/*
 * Servizio che si occupa della gestione delle Survey
*/
module SurveyService {
    let app = angular.module("app");

    app.factory("SurveyService", ["$resource", "GlobalApplicationData", ($resource: ng.resource.IResourceService, GlobalApplicationData: any) => {
        let serv = {
            // Recupera i template delle survey associate ad un Item
            getSurveysTemplateByReferenceId: $resource(GlobalApplicationData.applicationContext + 'rest-api/library-mediator/get-surveys-template-by-reference-id/:referenceId', {
                referenceId: "@referenceId"
            }, {
                query: {
                     method: 'GET',
                     isArray: false
                }
            }, {
                cancellable: true
            }),

            // Recupera tutte le Survey dell'utente loggato
            getSurveys: $resource(GlobalApplicationData.applicationContext + 'rest-api/library-mediator/get-surveys/:referenceId', {
                referenceId: "@referenceId"
            }, {
                query: {
                     method: 'GET',
                     isArray: false
                }
            }, {
                cancellable: true
            }),

            // Recupera la struttura della survey
            getFlatSurveyBySurveyId: $resource(GlobalApplicationData.applicationContext + 'rest-api/library-mediator/get-flat-survey-by-surveyId/:surveyId', {
                surveyId: "@surveyId"
            }, {
                query: {
                     method: 'GET',
                     isArray: false
                }
            }, {
                cancellable: true
            }),

            // Recupera i dettagli di una specifica Survey
            getSurveyBySurveyId: $resource(GlobalApplicationData.applicationContext + 'rest-api/library-mediator/get-survey-by-surveyId/:surveyId', {
                surveyId: "@surveyId"
            }, {
                query: {
                     method: 'GET',
                     isArray: false
                }
            }, {
                cancellable: true
            }),

            // Aggiorna la Survey
            updateSurvey: $resource(GlobalApplicationData.applicationContext + 'rest-api/library-mediator/update-survey', {
            }, {
                query: {
                     method: 'POST',
                     isArray: false
                }
            }, {
                cancellable: true
            })
        }; 
        return serv;
    }]);
}